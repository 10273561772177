import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

// 导出路由 在 main.js 里使用
const router = new VueRouter({
  routes,
  mode: 'hash'
})

/**
 * 路由拦截
 */
router.beforeEach((to, from, next) => {
  // console.log('from', from)
  document.title = to.meta.title
  next()
})

export default router
