// import axios from 'axios';
import request from '../plugins/request'

// 获取图片列表
export function listPic(data) {
  return request({
    url: '/order/listPic',
    method: 'post',
    data
  })
}

// 删除图片
export function delPic(data) {
  return request({
    url: '/order/delPic',
    method: 'post',
    data
  })
}
// 上传图片
// export function uploadPic(data) {
//   return request({
//     url: '/uploadPic',
//     method: 'post',
//     data
//   })
// }
// 删除图片
export function duplicateOrder(data) {
  return request({
    url: '/order/duplicate',
    method: 'post',
    data
  })
}
