import Vue from 'vue'
import router from './src/router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// import './src/plugins'

import App from './App'

Vue.use(ElementUI)

new Vue({
  render: (h) => h(App),
  router
}).$mount('#app')
