import axios from 'axios'

const service = axios.create({
  baseURL: 'https://newapi.fjhengtaixiang.cn',
  // baseURL: '/api',
  timeout: 5000 // 请求超时时间
})

function errorCreate(msg) {
  const err = new Error(msg)
  // errorLog(err)
  throw err
}

service.interceptors.response.use(
  r => {
    const axiosData = r.data
    const { code } = axiosData
    switch (code) {
      case 0:
        return axiosData.data
      default:
        errorCreate(`${axiosData.msg}: ${r.config.url}`)
        break
    }
  },
  e => {
    // if(r&&r.response){

    // }
    console.log(e)
    return Promise.reject(e)
  }
)

export default service
