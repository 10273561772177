const routes = [
  {
    path: '/',
    redirect: '/order/uploadpic'
  },
  // {
  //   path: '/portal',
  //   name: 'portal',
  //   meta: {
  //     title: '首页'
  //   },
  //   component: () => import('../pages/portal')
  // },
  {
    path: '/order/uploadpic',
    name: '/order/uploadpic',
    meta: {
      title: '图片上传'
    },
    component: () => import('../pages/picHtx')
  },
  {
    path: '/carwarehouse_setuser',
    name: '/carwarehouse_setuser',
    meta: {
      title: '仓库权限设置'
    },
    component: () => import('../pages/authHtx')
  },
  {
    path: '/OrdersFinancial/MultiEdit',
    name: '/OrdersFinancial/MultiEdit',
    meta: {
      title: '司机提成'
    },
    component: () => import('../pages/moneyHtx')
  },
  {
    path: '/PriceConfirm/BatchAuditing',
    name: '/PriceConfirm/BatchAuditing',
    meta: {
      title: '应付单价'
    },
    component: () => import('../pages/priceHtx')
  },
  {
    path: '/OrdersAuditing/SetOrderStatus',
    name: '/OrdersAuditing/SetOrderStatus',
    meta: {
      title: '派单'
    },
    component: () => import('../pages/distributeOrder')
  },
  {
    path: '/order/duplicate',
    name: '/order/duplicate',
    meta: {
      title: '数量'
    },
    component: () => import('../pages/numHtx')
  }
]

export default routes
